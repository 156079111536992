import React from "react"
import Layout from "../components/Layout/Layout"
import PricingTable from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"

const FaqPage = () => (
  <Layout header={true} footer={true}>
    <SEO title="Home" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column content">
            <h2>FAQ</h2>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FaqPage;
